html, body { width: 100%; height: 100%; }
body { margin: 0; overflow: hidden; }
canvas { width: 100%; height: 100%; }
#btns { position: absolute; }
#play {
  right: 0;
  position: absolute;
  font-size: 40px;
}
#messages, #form {
  bottom: 0;
  position: absolute;
  color: #000;
}
#messages {
  max-height: 200px;
  overflow: scroll;
  padding-bottom: 90px;
}
#messages::-webkit-scrollbar {
    display: none;
}
#allbtns {
position: absolute;
margin-top: 50px;
}

.bar {
  display: none;
  position: absolute;
  bottom: 50;
  /* top: 50%; */
  left: 50%;
  transform: translate(-50%);
}

#hit {
  top: 50%;
  width: 100px;
  
}
#bar {
  width: 300px;
}

#bar_head {
  top: -2%;
  height: 50px;
}

.bar_wraper {
  height: 100px;
  width: 300px;
  /* position: relative; */
}

.bar img {
  position:absolute;
  left: 50%;
  transform: translate(-50%);
}

.bar_head_wrapper {
  pointer-events: none;
  transform: rotate(355deg);
  transform-origin: bottom center;
  height: 400px;
}